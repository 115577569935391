import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Banded Good Mornings & Lateral Band Walks 3×10 each`}</p>
    <p>{`Deadlifts 4×4 to a 4RM`}</p>
    <p>{`then,`}</p>
    <p>{`Death by:`}</p>
    <p>{`Deadlift (225/155)`}</p>
    <p>{`Bar Facing Burpee`}</p>
    <p>{`1 rep of each the first minute`}</p>
    <p>{`2 reps of each the second minute`}</p>
    <p>{`etc until time runs out.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      